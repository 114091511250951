<template>
  <div class="flex flex-col gap-y-2">
    <div id="sales-report-header" class="flex flex-col gap-y-3 p-3 bg-white dark:bg-gray-900 rounded-lg shadow-lg">
      <div id="filters" class="flex flex-col sm:flex-row gap-x-3 gap-y-2">
        <div class="flex-grow flex flex-wrap">
          <div id="order-adv-filters-1" class="flex flex-wrap items-center gap-3">
            <div class="flex flex-wrap items-center gap-3">

              <BaseFilterSelectWithAddon
                class="w-full md:min-w-max flex-0 sm:flex-1"
                v-model="activeFilters.year"
                label="Rok"
                :options="years"
                :nullOption="false"
              />

              <BaseFilterSelectWithAddon
                class="w-full md:min-w-max flex-0 sm:flex-1"
                v-model="activeFilters.month"
                label="Miesiąc należności raty"
                :options="months"
                :nullOption="false"
              />

              <div class="hidden sm:flex w-full md:min-w-max flex-1 items-center">
                <button @click="prevMonth" class="tw--link text-gray-400 dark:text-gray-500 px-2 py-0.5 focus:outline-none focus:ring-1 focus:ring-blue-100 dark:ring-gray-700">
                  <FontAwesomeIcon icon="chevron-left" size="lg" />
                </button>
                <button @click="setToday" class="tw--btn tw--btn-secondary">dzisiaj</button>
                <button @click="nextMonth" class="tw--link text-gray-400 dark:text-gray-500 px-2 py-0.5 focus:outline-none focus:ring-1 focus:ring-blue-100 dark:ring-gray-700">
                  <FontAwesomeIcon icon="chevron-right" size="lg" />
                </button>
              </div>

            </div>

            <div class="flex flex-wrap items-center gap-3">
              <BaseFilterSelectWithAddon
                class="w-full md:min-w-max flex-0 sm:flex-1"
                v-model="activeFilters.employee_id"
                label="Agent"
                :options="employeeSimpleList"
                :nullOptionName="accessType === 'all' ? 'wszyscy' : accessType === 'subs' ? 'zespół' : ''"
              />
              <button
                @click="showOrders"
                class="tw--btn"
                :class="activeFilters.showOrders === '1' ? 'tw--btn-warning' : 'tw--btn-secondary'"
              >{{ activeFilters.showOrders === '0' ? 'pokaż polisy' : 'ukryj polisy' }}</button>
            </div>

          </div>
        </div>

        <div id="clear-refresh-buttons" class="flex order-first sm:order-last">
          <div class="flex-grow sm:hidden flex items-center">
            <button @click="prevMonth" class="tw--link text-gray-400 dark:text-gray-500 px-2 py-0.5 focus:outline-none focus:ring-1 focus:ring-blue-100 dark:ring-gray-700">
              <FontAwesomeIcon icon="chevron-left" size="lg" />
            </button>
            <button @click="setToday" class="tw--btn tw--btn-secondary">dzisiaj</button>
            <button @click="nextMonth" class="tw--link text-gray-400 dark:text-gray-500 px-2 py-0.5 focus:outline-none focus:ring-1 focus:ring-blue-100 dark:ring-gray-700">
              <FontAwesomeIcon icon="chevron-right" size="lg" />
            </button>
          </div>
          <div class="flex gap-0.5 justify-end items-start">
            <button @click="resetFilters" class="tw--btn tw--btn-filter shadow whitespace-nowrap" title="wyczyść wszystkie filtry">
              <FontAwesomeIcon icon="times" size="xs" /> <FontAwesomeIcon icon="filter" />
            </button>
            <button @click="init" class="tw--btn tw--btn-filter shadow whitespace-nowrap" title="odśwież dane z serwera">
              <FontAwesomeIcon icon="sync-alt" size="xs" /> <FontAwesomeIcon icon="database" />
            </button>
          </div>
        </div>
      </div>
      <div class="flex flex-wrap gap-x-3 gap-y-1">
        <button @click="getReport" class="tw--btn">Generuj raport</button>
      </div>
    </div>

    <div id="sales-report-content" class="p-3 pt-1 bg-white dark:bg-gray-900 rounded-lg shadow-lg">
      <div class="flex flex-col">
        <span class="font-semibold">Raport prognozowanej prowizji wg daty należności rat</span>
      </div>

      <hr class="dark:border-gray-600 -mx-3">

      <div class="flex flex-wrap gap-x-5 gap-y-1 mt-2 mb-3">
        <div class="w-full sm:w-auto flex flex-nowrap font-semibold text-gray-200 dark:text-gray-400">
          <span class="flex-grow sm:flex-grow-0 px-3 py-1 rounded-l bg-black">Agent</span>
          <span class="px-3 py-1 rounded-r bg-blue-500 dark:bg-blue-900">{{ chosenEmployeeName }}</span>
        </div>

        <div v-if="currentUserType !== 'owfca'" class="w-full sm:w-auto flex flex-nowrap font-semibold text-gray-200 dark:text-gray-400">
          <span class="flex-grow sm:flex-grow-0 px-3 py-1 rounded-l bg-black">progn. prowizja agenta</span>
          <span class="px-3 py-1 rounded-r bg-indigo-500 dark:bg-indigo-900">{{ report01Sums.prov_a | moneyPL }}</span>
        </div>

        <div v-if="showOfficeProvision" class="w-full sm:w-auto flex flex-nowrap font-semibold text-gray-200 dark:text-gray-400">
          <span class="flex-grow sm:flex-grow-0 px-3 py-1 rounded-l bg-black">progn. prowizja agencji</span>
          <span class="px-3 py-1 rounded-r bg-purple-500 dark:bg-purple-900">{{ report01Sums.prov_b | moneyPL }}</span>
        </div>
      </div>

      <div class="flex flex-wrap">
        <div class="w-full xl:w-1/2 pr-5">
          <div class="hidden sm:flex border-b dark:border-gray-600 font-semibold">
            <div class="flex-grow">kategoria</div>
            <!-- <div class="w-1/5 text-right">składka ogółem</div> -->
            <div class="w-1/5 text-right">należne raty</div>
            <div v-if="currentUserType !== 'owfca'" class="w-1/5 text-right">prow. agenta</div>
            <div v-if="showOfficeProvision" class="w-1/5 text-right">prow. agencji</div>
          </div>
          <div v-for="(row, index) in report01" :key="index" class="flex flex-col sm:flex-row border-b dark:border-gray-600 border-dashed border-opacity-25">
            <div class="flex-grow">
              {{ row.category_name }} <span class="text-muted">[{{ report01Sums.value ? (row.subsum_skladka * 100 / report01Sums.value).toFixed(2) : 0 }}%]</span></div>
            <!-- <div class="w-full sm:w-1/5 flex justify-between sm:justify-end">
              <div class="block sm:hidden text-muted">składka ogółem</div><div>{{ row.subsum_skladka | moneyPL }}</div>
            </div> -->
            <div class="w-full sm:w-1/5 flex justify-between sm:justify-end">
              <div class="block sm:hidden text-muted">raty</div><div>{{ row.subsum_rata | moneyPL }}</div>
            </div>
            <div v-if="currentUserType !== 'owfca'" class="w-full sm:w-1/5 flex justify-between sm:justify-end">
              <div class="block sm:hidden text-muted">prow. agenta</div><div>{{ row.prowizja_agent | moneyPL }}</div>
            </div>
            <div v-if="showOfficeProvision" class="w-full sm:w-1/5 flex justify-between sm:justify-end">
              <div class="block sm:hidden text-muted">prow. agencji</div><div>{{ row.prowizja_biuro | moneyPL }}</div>
            </div>
          </div>
        </div>
        <div v-show="showChartsComp" class="w-full xl:w-1/2 flex flex-col gap-y-5">
          <div class="flex flex-col items-center">
            <div class="text-base font-bold">Prowizja agenta wg. kategorii</div>
            <canvas id="report-chart1"></canvas>
          </div>
          <div v-if="showOfficeProvision" class="flex flex-col items-center">
            <div class="text-base font-bold">Prowizja agencji wg. kategorii</div>
            <canvas id="report-chart2"></canvas>
          </div>
        </div>
      </div>
    </div>
    <div v-if="orders.length > 0" id="order-list" class="p-3 pt-1 bg-white dark:bg-gray-900 rounded-lg shadow-lg">
      <div class="font-semibold">Lista polis</div>
      <div class="block sm:hidden px-2 py-0.5 -mx-2 text-gray-300 bg-yellow-500 dark:bg-yellow-800 text-sm rounded-sm text-center">
        Dla pełnej czytelności, tabelę "Lista polis" zaleca sie przeglądać na urządzeniach z rozdzielczością HD[1920x1080px]
      </div>
      <div id="order-list-header" class="flex flex-nowrap items-center border-b dark:border-gray-600 text-sm sm:text-xs xl:text-sm font-semibold">
        <div class="w-10">lp.</div>
        <div class="w-4/12 2xl:w-3/12 flex flex-wrap">
          <div class="w-full xl:w-1/2">nr polisy</div>
          <div class="w-full xl:w-1/2">TU</div>
        </div>
        <div class="hidden xl:block w-2/12 2xl:w-1/12">kategoria</div>
        <div class="flex-grow">
          <div class="block xl:hidden w-1/12">kategoria</div>
          <div class="w-full">klient</div>
        </div>
        <div class="w-4/12 flex flex-col sm:flex-row">
          <div class="w-full sm:w-1/4 flex justify-end" title="składka ogółem">składka<span class="hidden 2xl:inline"> ogółem</span></div>
          <div class="w-full sm:w-1/4 flex justify-end" title="aktualna należna rata"><span class="hidden 2xl:inline">aktualna </span>rata</div>
          <div class="w-full sm:w-1/4 flex justify-end" title="Prognozowana prowizja agenta"><span class="hidden 2xl:inline">prow. </span>agent</div>
          <div class="w-full sm:w-1/4 flex justify-end" v-if="showOfficeProvision" title="Prognozowana prowizja agencji"><span class="hidden 2xl:inline">prow. </span>agencja</div>
        </div>
      </div>

      <router-link
        v-for="(order, index) in orders"
        :to="{ name: 'Order', params: { id: order.id } }"
        target="_blank"
        :key="index"
        class="flex flex-nowrap items-center border-b dark:border-gray-600 border-dashed border-opacity-25 text-sm sm:text-xs xl:text-sm"
      >
        <div class="w-10">{{ index + 1 }}</div>
        <div class="w-4/12 2xl:w-3/12 flex flex-wrap">
          <div class="w-full xl:w-1/2">{{ order.policy_no }}</div>
          <div class="w-full xl:w-1/2">{{ order.insurer_name }}</div>
        </div>
        <div class="hidden xl:block w-2/12 2xl:w-1/12">{{ order.category_name }}</div>
        <div class="flex-grow">
          <div class="block xl:hidden w-1/12">{{ order.category_name }}</div>
          <div class="w-full">{{ order.customer_name }}</div>
        </div>
        <div class="w-4/12 flex flex-col sm:flex-row">
          <div class="w-full sm:w-1/4 flex justify-end" title="składka ogółem">{{ order.skladka | moneyPL }}</div>
          <div class="w-full sm:w-1/4 flex justify-end" title="aktualna należna rata">{{ order.rata | moneyPL }}</div>
          <div class="w-full sm:w-1/4 flex justify-end" title="Prognozowana prowizja agenta">{{ order.prowizja_agent | moneyPL }}</div>
          <div class="w-full sm:w-1/4 flex justify-end" v-if="showOfficeProvision" title="Prognozowana prowizja agencji">{{ order.prowizja_biuro | moneyPL }}</div>
        </div>
      </router-link>
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import { errorHandler } from '../../mixins/errorHandler';
import Chart from 'chart.js';
import moment from 'moment';
import ReportsService from '../../services/ReportsService';

export default {
  name: 'ProvisionEstimation',
  mixins: [errorHandler],
  data() {
    return {
      report01: [],
      orders: [],
      chosen_employee_id: 0,
      accessType: '',
      report01Sums: {
        value: 0,
        installment: 0,
        prov_a: 0,
        prov_b: 0,
      },
      pieChartColors: ['rgba(18,152,208, 0.6)', 'rgba(66,161,80, 0.6)', 'rgba(251,207,68, 0.6)', 'rgba(100,59,161, 0.6)', 'rgba(248,64,50, 0.6)'],
      activeFilters: {
        year: '',
        month: '',
        employee_id: '',
        showOrders: '0',
      },
      saveErrors: {},
      chartData: [],
      showCharts: true,
    };
  },
  computed: {
    ...mapGetters([
      'currentUserType',
    ]),
    ...mapState('employees', [
      'employeeSimpleList',
    ]),
    showOfficeProvision() {
      return this.currentUserType === 'root' || this.currentUserType === 'admin';
    },
    years() {
      const startY = moment().subtract(1, 'years').format('YYYY');
      const yearsArr = [];
      for (let i = 0; i < 10; i += 1) {
        yearsArr.push({ id: Number(startY) + i, name: Number(startY) + i });
      }
      return yearsArr;
    },
    months() {
      const months = [];
      for (let index = 1; index < 13; index++) {
        const month = (`${index}`).length === 1 ? `0${index}` : `${index}`;
        months.push({ id: month, name: `${index}` });
        // months.push({ id: index, name: index });
      }
      return months;
    },
    chosenEmployeeName() {
      if (this.chosen_employee_id === 0) {
        return this.accessType === 'all' ? 'Wszyscy' : 'Zespół';
      }
      if (Number.isInteger(this.chosen_employee_id) && this.chosen_employee_id > 0) {
        const empl = this.employeeSimpleList.find(obj => Number(obj.id) === this.chosen_employee_id);
        if (empl !== undefined) {
          return empl.fullname;
        }
      }
      return 'b/d';
    },
    showChartsComp() {
      return this.showCharts && this.orders.length === 0 && this.report01.length > 0;
    },
  },
  mounted() {
    this.$store.dispatch('employees/getEmployeeSipleList').catch(error => this.resolveError(error));
    this.activeFilters.year = moment().format('YYYY');
    this.activeFilters.month = moment().format('MM');
    this.init();
  },
  methods: {
    init() {
      Object.keys(this.activeFilters).forEach((key) => {
        if (this.$route.query[key] !== undefined && this.$route.query[key] !== '') {
          this.activeFilters[key] = decodeURI(this.$route.query[key]);
        }
      });
      this.getReport();
    },
    getReport() {
      this.$store.dispatch('setLoadingData', true);
      this.saveErrors = {};
      ReportsService.getProvisionEstimationReport(this.activeFilters)
        .then((response) => {
          this.report01 = response.data.report01;
          this.orders = response.data.orders;
          this.chosen_employee_id = Number(response.data.chosen_employee_id);
          this.accessType = response.data.access_type;
          this.report01Sums = {
            value: 0, installment: 0, prov_a: 0, prov_b: 0,
          };
          this.setUrl();
          this.chartData = [];
          this.chartData.push({ type: 'pie', data: { labels: [], datasets: [{ label: 'Prowizja agent', data: [], backgroundColor: [] }] } });
          if (this.showOfficeProvision) {
            this.chartData.push({ type: 'pie', data: { labels: [], datasets: [{ label: 'Prowizja biuro', data: [], backgroundColor: [] }] } });
          }
          this.report01.forEach((obj, index) => {
            this.chartData[0].data.labels.push(obj.category_name);
            this.chartData[0].data.datasets[0].data.push(obj.prowizja_agent);
            this.chartData[0].data.datasets[0].backgroundColor.push(this.pieChartColors[index]);
            if (this.showOfficeProvision) {
              this.chartData[1].data.labels.push(obj.category_name);
              this.chartData[1].data.datasets[0].data.push(obj.prowizja_biuro);
              this.chartData[1].data.datasets[0].backgroundColor.push(this.pieChartColors[index]);
            }
            this.report01Sums.value += Number(obj.subsum_skladka);
            this.report01Sums.installment += Number(obj.subsum_rata);
            this.report01Sums.prov_a += Number(obj.prowizja_agent);
            if (this.showOfficeProvision) {
              this.report01Sums.prov_b += Number(obj.prowizja_biuro);
            }
          });
          // this.$store.dispatch('setTemporaryAlert', {message: response.data.msg, type: 'success'})
          this.$nextTick(() => this.createChart('report-chart1', this.chartData[0]));
          if (this.showOfficeProvision) {
            this.$nextTick(() => this.createChart('report-chart2', this.chartData[1]));
          }
          this.$store.dispatch('setLoadingData', false);
        })
        .catch((error) => {
          this.saveErrors = this.resolveError(error);
          this.$store.dispatch('setLoadingData', false);
        });
    },
    prevMonth() {
      const pevDate = moment(`${this.activeFilters.year}-${this.activeFilters.month}-01`).subtract(1, 'months');
      this.activeFilters.year = moment(pevDate).format('YYYY');
      this.activeFilters.month = moment(pevDate).format('MM');
      this.activeFilters.showOrders = '0';
      this.getReport();
    },
    setToday() {
      this.activeFilters.year = moment().format('YYYY');
      this.activeFilters.month = moment().format('MM');
      this.activeFilters.showOrders = '0';
      this.getReport();
    },
    nextMonth() {
      const nextDate = moment(`${this.activeFilters.year}-${this.activeFilters.month}-01`).add(1, 'months');
      this.activeFilters.year = moment(nextDate).format('YYYY');
      this.activeFilters.month = moment(nextDate).format('MM');
      this.activeFilters.showOrders = '0';
      this.getReport();
    },
    showOrders() {
      this.activeFilters.showOrders = this.activeFilters.showOrders === '1' ? '0' : '1';
      this.getReport();
    },
    setUrl() {
      Object.keys(this.activeFilters).forEach((key) => {
        this.activeFilters[key] = encodeURI(this.activeFilters[key]);
      });
      if (JSON.stringify(this.$route.query) !== JSON.stringify(this.activeFilters)) {
        this.$router.replace({ name: 'ProvisionEstimation', query: this.activeFilters });
      }
    },
    resetFilters() {
      Object.keys(this.activeFilters).forEach((key) => {
        this.activeFilters[key] = '';
      });
      this.setToday();
      this.getReport();
    },
    goTo(path) {
      this.$router.push(path);
    },
    createChart(chartId, chartData) {
      const ctx = document.getElementById(chartId);
      // eslint-disable-next-line no-unused-vars
      const myChart = new Chart(ctx, {
        type: chartData.type,
        data: chartData.data,
        options: chartData.options,
      });
    },
  },
};
</script>
